<template>
  <v-app>
    <div>
      <BreadcrumbComponent :items=items></BreadcrumbComponent>
      <v-row justify="center">
        <v-dialog v-model="dialog_stock" scrollable max-width="600px">
          <template>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ form_title_stock }}</span>
            </v-card-title>
            <v-card-text>
              <v-row style="margin-top:15px">
                <v-col cols="12" sm="12" md="12">
                  <label>Type *</label>
                  <select v-model=stock.type class="form-control">
                    <option v-for="type in types" v-bind:key="type.id" :value="stock.type">{{ type.name }}</option>
                  </select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>Quantité *</label>
                  <input label="Quantité *" required v-model=stock.quantity placeholder="Quantité *"
                    class="form-control">
                </v-col>

                <v-col cols="12" sm="12">
                  <label>Coût * (<small v-if='product.rate_id == 1' style="color:green;font-weight:bold"> {{
                    product.cost / rate |
                      formatNumber }} USD</small>
                    <small v-if='product.rate_id == 2' style="color:green;font-weight:bold"> {{ stock.cost * rate |
                      formatNumber }}
                      HTG</small></label>)
                  <input label="Coût *" required v-model=stock.cost placeholder="Coût *" class="form-control">

                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <label>Prix * (<small v-if='product.rate_id == 1' style="color:green;font-weight:bold"> {{
                    product.price / rate |
                      formatNumber }} USD</small>
                    <small v-if='product.rate_id == 2' style="color:green;font-weight:bold"> {{ product.price * rate |
                      formatNumber
                      }} HTG</small>)</label>
                  <input label="Prix *" required v-model=product.price placeholder="Prix *" class="form-control">
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if='min_price'>
                  <label>Prix Minimum * (<small v-if='product.rate_id == 1' style="color:green;font-weight:bold"> {{
                    product.min_price / rate | formatNumber }} USD</small>
                    <small v-if='product.rate_id == 2' style="color:green;font-weight:bold"> {{ product.min_price * rate |
                      formatNumber }} HTG</small>)</label>
                  <input label="Prix Minimum *" required v-model=product.min_price placeholder="Prix Minimum *"
                    class="form-control">
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <label>Mémo</label>
                  <input label="Mémo *" required v-model=stock.memo placeholder="Mémo" class="form-control">
                </v-col>
              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="close_modal_button" text @click="close_stock">
                Fermer
              </v-btn>
              <v-btn color="blue" text class="confirm_modal_button" v-on:click=save_stock :loading="loading"
                :disabled="loading" @click="loader = 'loading'">
                Valider
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" @click='create'>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ form_title }}</span>
            </v-card-title>
            <v-card-text>
              <v-row style="margin-top:15px">
                <v-col cols="12" sm="12" md="12">
                  <label>Nom</label>
                  <input label="Nom *" required v-model=product.name v-on:keyup="validateName()" placeholder="Nom *"
                    class="form-control"
                    :style="validate_name ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                  <small v-if="!validate_name" style="color:#ff7370;font-style:italic">{{ validate_name_error_message
                    }}</small>

                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <label>Code Barre</label>
                  <input label="Code Barre" required v-model=product.barcode placeholder="Code Barre"
                    class="form-control">

                </v-col>
                <v-col cols="6" sm="6">
                  <label>Catégorie</label>
                  <select v-model=product.category_id class="form-control">
                    <option value="" selected>-- Catégorie --</option>
                    <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">
                      {{ category.name }}</option>
                  </select>
                </v-col>
                <v-col cols="6" sm="6">
                  <label>Coût * (<small v-if='product.rate_id == 1' style="color:green;font-weight:bold"> {{
                    product.cost / rate
                      | formatNumber }} USD</small>
                    <small v-if='product.rate_id == 2' style="color:green;font-weight:bold"> {{ product.cost * rate |
                      formatNumber }} HTG</small></label>)
                  <input label="Coût *" required v-model=product.cost v-on:keyup="validateCost()" placeholder="Coût *"
                    class="form-control"
                    :style="validate_cost ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">

                  <small v-if="!validate_cost" style="color:#ff7370;font-style:italic">{{ validate_cost_error_message
                    }}</small>

                </v-col>
                <v-col cols="6" sm="6">
                  <label>Prix * (<small v-if='product.rate_id == 1' style="color:green;font-weight:bold"> {{
                    product.price / rate | formatNumber }} USD</small>
                    <small v-if='product.rate_id == 2' style="color:green;font-weight:bold"> {{ product.price * rate |
                      formatNumber }} HTG</small>)</label>
                  <input label="Prix *" required v-model=product.price v-on:keyup="validatePrice()" placeholder="Prix *"
                    class="form-control"
                    :style="validate_price ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">

                  <small v-if="!validate_price" style="color:#ff7370;font-style:italic">{{ validate_price_error_message
                    }}</small>
                </v-col>
                <v-col cols="6" sm="6" v-if='min_price'>
                  <label>Prix Minimum * (<small v-if='product.rate_id == 1' style="color:green;font-weight:bold"> {{
                    product.min_price / rate | formatNumber }} USD</small>
                    <small v-if='product.rate_id == 2' style="color:green;font-weight:bold"> {{ product.min_price * rate |
                      formatNumber }} HTG</small>)</label>
                  <input label="Prix Minimum *" required v-model=product.min_price v-on:keyup="validateMinPrice()"
                    placeholder="Prix Minimum *" class="form-control"
                    :style="validate_min_price ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                  <small v-if="!validate_min_price" style="color:#ff7370;font-style:italic">{{
                    validate_min_price_error_message }}</small>
                </v-col>
              </v-row>
              <p
                style="padding:10px;border-radius:3px;background:#d9edf7; border:1px solid #c7e2f0;text-align:left;color:black;margin-top:30px">
                La fiche produit est un élément essentiel d'un
                système de caisse. Nous vous recommandons les éléments suivants :
              <ul>
                <li>Utilisez la même structure de noms pour tous vos produits, par exemple : MARQUE - NOM PRODUIT -
                  TAILLE. Un
                  exemple respectant cette structure :
                  <strong>MOBIWIRE POS MP4+ 1 UNITE</strong>
                </li>
                <li>Assurez-vous de bien catégoriser vos produits. Vos vendeurs pourront plus facilement les localiser
                  lorsqu'ils effectuent une vente.</li>
                <li>Utilisez le même langage à travers toute l'application. Si vous décidez de nommer vos produits en
                  Francais, continuez en Francais, et évitez de mélanger les langues.</li>
              </ul>
              </p>
              <!--           </v-container>
 -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="close_modal_button" text @click="close">
                Fermer
              </v-btn>
              <v-btn color="blue" text class="confirm_modal_button" v-on:click=save :loading="loading"
                :disabled="loading" @click="loader = 'loading'">
                Valider
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <PreloaderComponent :enable=enable></PreloaderComponent>

      <v-container fluid class="settingscontainer" v-if=enable>
        <input type="file" ref="file" style="display: none" @change="uploadFile()">
        <v-card elevation="2" class="text-right">
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher un Produit" single-line
                hide-details class="tablesearch"></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="rows" :items-per-page="10" class="elevation-1" :search="search">
              <template v-slot:item.cost="{ item }">
                {{ formatNumber(item.cost, item.rate.name) }}
              </template>
              <template v-slot:item.image_url="{ item }">
                <v-icon v-if="!item.image_url" style="cursor:pointer;" @click="editFile(item)">
                  mdi-image-outline
                </v-icon>
                <img @click="editFile(item)" style="height:33px;width:auto;cursor:pointer" v-if="item.image_url"
                  v-bind:src="'https://api.keslakay.com/' + item.image_url">
                <v-icon v-if="item.image_url"
                  style="cursor:pointer;color:white;background: red;padding:0px;font-size:13px;border-radius:3px;border:2px solid red;margin-top:-15px;margin-left:-5px"
                  @click="removeFile(item)">
                  mdi-close
                </v-icon>
              </template>
              <template v-slot:item.price="{ item }">
                {{ formatNumber(item.price, item.rate.name) }}
              </template>
              <template v-slot:item.min_price="{ item }">
                {{ formatNumber(item.min_price, item.rate.name) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2 edit_button" @click="edit_stock(item)">
                  mdi-cube
                </v-icon>
                <v-icon small class="mr-2 edit_button" @click="edit(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card>

        </v-card>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import numeral from 'numeral'

export default {
  name: 'ProductsView',
  methods: {
    load: function () {
      var self = this;
      this.$axios.get('/products?include=category,rate,store_products&orderasc=name')
        .then(function (response) {
          self.rows = response.data
          self.enable = true
        })
    },
    getCategories: function () {
      var self = this;
      this.$axios.get('/categories')
        .then(function (response) {
          self.categories = response.data
        })
    },
    editFile: function (item) {
      this.$refs.file.click()
      this.file.product_id = item.id
      this.file_index = this.rows.indexOf(item)
      this.product = Object.assign({}, item)
      // this.file.image = 
    },
    uploadFile: function () {
      var image = this.$refs.file.files[0]
      var self = this;
      const formData = new FormData();
      formData.append('file', image);
      var url = '/products/upload_image/' + this.product.id
      Object.assign(this.$axios.defaults.headers, { 'Content-Type': 'multipart/form-data' })
      var formdata = new FormData();
      formdata.append('image', image);
      var config = {
        method: 'post',
        url: url,
        data: formdata
      };
      this.$axios(config)
        .then(response => {
          Object.assign(self.rows[self.file_index], response.data)
        })
    },
    removeFile: function (item) {
      this.file_index = this.rows.indexOf(item)
      var self = this
      this.product = Object.assign({}, item)
      this.product.image_url = ''
      var url = '/products/' + this.product.id + '?include=category,rate,store_products'
      this.$axios.put(url + "&data=" + encodeURIComponent(JSON.stringify(self.product)))
        .then(response => {
          var product = response.data
          Object.assign(self.rows[self.file_index], product)
          self.dialog = false
          self.loading = false
          self.reset()
        })

    },
    formatNumber: function (cost, rate) {
      return numeral(cost).format('0,0.00') + " " + rate
    },
    save: function (status = false) {
      this.validatePrice()
      this.validateName()
      this.validateCost()
      if ((this.validatePrice() && this.validateName() && this.validateCost() && this.product.category_id != '') || status) {
        var self = this
        this.loading = true
        this.product.rate_id = this.rate_id
        var url = ''
        if (this.index > -1) {
          url = '/products/' + this.product.id + '?include=category,rate,store_products'
          for (var i = this.product.store_products.length - 1; i >= 0; i--) {
            this.product.store_products[i].price = this.product.price
            this.product.store_products[i].cost = this.product.cost
          }
          this.$axios.put(url + "&data=" + encodeURIComponent(JSON.stringify(self.product)))
            .then(response => {
              var product = response.data
              product = this.setStore(product)
              Object.assign(self.rows[self.index], product)
              self.reset_stock()
              self.dialog = false
              self.loading = false
              self.reset()
            })
        } else {
          url = '/products?include=category,rate,store_products'

          var user = JSON.parse(localStorage.authentication).user.id
          this.product.user_id = user

          this.$axios.post(url + "&data=" + JSON.stringify(self.product))
            .then(response => {
              var product = response.data
              product = this.setStore(product)
              self.rows.push(product)
              self.index = this.rows.indexOf(product)

              self.dialog = false
              self.loading = false
              self.reset()
            }).catch(function (error) {
              console.log(error.message)
            })
        }
      } else {
        alert("Vérifiez les données du formulaire puis réessayez. Avez-vous bien choisit une catégorie?")
      }
    },
    setStore: function (product) {
      var url = '/store-products'
      var store_product = { store_id: this.store_id, product_id: product.id, price: product.price, cost: product.cost, min_price: product.min_price }
      this.$axios.post(url + "?data=" + JSON.stringify(store_product))
        .then(response => {
          product.store_products = response.data
        }).catch(function (error) {
          console.log(error.message)
        })

      return product
    },
    validateName: function () {
      if (this.product.name == '') {
        this.validate_name = false
        this.validate_name_error_message = "Le nom ne peut pas être vide."
        return false
      } else {
        this.validate_name = true
        return true
      }
    },
    validateCost: function () {
      if (this.product.cost == '') {
        this.validate_cost = false
        this.validate_cost_error_message = "Le coût ne peut pas être vide. Il peut être à 0."
        return false
      } else {
        this.validate_cost = true
        return true
      }
    },
    validatePrice: function () {
      if (this.product.price == '') {
        this.validate_price = false
        this.validate_price_error_message = "Le prix ne peut pas être vide. Il peut être à 0."
        return false
      } else {
        this.validate_price = true
        return true
      }
    },
    validateMinPrice: function () {
      if (this.product.min_price == '') {
        this.validate_min_price = false
        this.validate_min_price_error_message = "Le prix ne peut pas être vide. Il peut être à 0."
        return false
      } else {
        this.validate_min_price = true
        return true
      }
    },
    edit: function (item) {
      this.index = this.rows.indexOf(item)
      this.product = Object.assign({}, item)
      this.form_title = "Editer Produit : " + this.product.name
      this.dialog = true
    },
    save_stock: function () {
      var self = this
      this.loading = true
      var url = ''
      url = '/operations'
      var user = JSON.parse(localStorage.authentication).user.id
      this.stock.user_id = user
      this.stock.product_id = this.product.id
      this.$axios.post(url + "?data=" + JSON.stringify(self.stock))
        .then(response => {
          self.dialog_stock = false
          console.log(response.data)
          self.product.stock = parseFloat(self.product.stock) + parseFloat(self.stock.quantity)
          self.save(true)
          self.loading = false
        }).catch(function (error) {
          console.log(error.message)
        })


    },
    edit_stock: function (item) {
      this.index = this.rows.indexOf(item)
      this.stock.cost = item.cost

      this.product = Object.assign({}, item)
      this.form_title_stock = "Nouvelle Opération : " + this.product.name
      this.dialog_stock = true
    },
    create: function () {
      this.reset()
    },
    close: function () {
      this.dialog = false
      this.reset()
      this.loading = false
    },
    close_stock: function () {
      this.dialog_stock = false
      this.reset_stock()
      this.loading = false
    },
    reset: function () {
      this.product = {
        name: '',
        barcode: '',
        cost: '',
        price: '',
        min_price: '',
        rate_id: 1,
        groupe_id: 1,
        category_id: '',
      }
      this.uploadedFiles = [];
      this.validate_cost = true
      this.validate_price = true
      this.validate_min_price = true
      this.validate_name = true
      this.uploadError = null;
      this.stores = []
      this.index = -1
      this.form_title = 'Nouveau Produit'
    },
    reset_stock: function () {
      this.stock = {
        product_id: '',
        user_id: '',
        cost: '',
        memo: '',
        type: 1,
        quantity: '',
      }
      this.index = -1
      this.form_title_stock = 'Nouvelle Opération'
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.reset()
      })
    },
    getStore() {
      this.authentication = JSON.parse(localStorage.authentication)
      this.business_id = JSON.parse(localStorage.business).id
      var self = this;
      this.$axios.get('/stores?orderasc=name&include=store_rates&business_id=' + this.business_id)
        .then(function (response) {
          var stores = response.data
          for (var i = stores.length - 1; i >= 0; i--) {
            self.store_id = stores[i].id
            self.rate_id = stores[i].rate_id
            self.min_price = stores[i].min_price
            console.log(stores[i].store_rates)
            for (var j = stores[i].store_rates.length - 1; j >= 0; j--) {
              if (stores[i].store_rates[j].rate_id == 2) {
                self.rate = stores[i].store_rates[j].sales_rate
              }
            }
          }
        })
    }
  },
  mounted() {
    this.load()
    this.getCategories()
    this.getStore()
    this.reset();
  },
  data: () => ({
    search: '',
    file_index: -1,
    file: {
      product_id: -1,
      image: []
    },
    index: -1,
    store_id: '',
    types: [{ name: "Réception", id: 1 }, { name: 'Ajustement', id: 2 }, { name: 'Autre', id: 3 }],
    rate_id: '',
    rate: '',
    loading: false,
    authentication: [],
    business_id: 0,
    min_price: false,
    form_title: "Nouveau Produit",
    form_title_stock: "Nouvelle Opération",
    items: [
      {
        text: 'Produits',
        disabled: false,
        href: '/products',
      }
    ],
    fluid: true,
    enable: false,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'image_url',
      },
      { text: 'CODE BARRE', value: 'barcode' },
      { text: 'NOM', value: 'name' },
      { text: 'CATEGORIE', value: 'category.name' },
      { text: 'COUT', value: 'cost' },
      { text: 'PRIX', value: 'price' },
      { text: 'STOCK', value: 'stock' },
      { text: 'ACTIONS', value: 'actions' },
    ],
    rows: [],
    roles: [],
    dialog: '',
    dialog_stock: '',
    dialogDelete: false,
    categories: [],
    validate_name: true,
    validate_min_price: false,
    validate_cost: true,
    validate_price: true,
    validate_name_error_message: 'Le nom ne peut pas être vide.',
    validate_cost_error_message: 'Vérifiez le format du coût.',
    validate_price_error_message: 'Vérifiez le format du prix.',
    validate_min_price_error_message: 'Vérifiez le format du prix minimum.',
    product: {
      name: '',
      barcode: '',
      min_price: '',
      cost: '',
      price: '',
      rate_id: 1,
      groupe_id: 1,
      category_id: '',
    },
    stock: {
      product_id: '',
      user_id: '',
      cost: '',
      memo: '',
      type: 1,
      quantity: '',
    }
  }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
.text-right {
  text-align: right;
}

.v-application--wrap .theme--dark.v-btn.v-btn--has-bg {
  background-color: #F68D3A !important;
}

.settingscontainer {
  margin-top: 10px !important;
}

.v-application a {
  color: black !important;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

th.text-start,
td.text-start {
  text-align: center !important;
}

th.text-start:first-child,
td.text-start:first-child {
  text-align: left !important;
}

th.text-start:last-child,
td.text-start:last-child {
  text-align: right !important;
}

.class_green {
  background: #4caf50 !important;
  color: white !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}

.class_red,
.confirm_delete_button {
  background: #ff5252 !important;
  color: white !important;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.tablesearch .v-input__slot:before {
  display: none;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

.edit_button {
  font-size: 16px;
  padding: 5px;
  background: #003f74 !important;
  color: white !important;
  border-radius: 4px;
}

.delete_button {
  background: #ff5252 !important;
  color: white !important;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
}

.v-application .justify-center {
  justify-content: right !important;
}

.v-application .v-btn {
  width: 70px !important;
  margin-right: 22px;
  margin-top: 18px;
}

.v-application--wrap .theme--dark.v-btn.v-btn--has-bg {
  background-color: #F68D3A !important;
}

.v-application .v-dialog .v-btn {
  width: 70px !important;
  margin-right: 22px;
  margin-top: 0px !important;
}

.v-dialog .v-card__title {
  background: #f3f3f3;
  border-bottom: 1px solid #ddd;
}

.v-btn.close_modal_button {
  background-color: #1976d2 !important;
  color: white !important;
}

.v-btn.confirm_modal_button {
  background-color: #4caf50 !important;
  color: white !important;
}

.v-dialog .v-card__actions {
  padding-bottom: 15px !important;
  padding-top: 20px !important;
  background: #f3f3f3;
  border-top: 1px solid #ddd;
}

.v-dialog button.v-btn {
  margin-right: 8px !important;
}

.color-red {
  color: red !important;
}

c .has_errors {
  padding-top: 0px;
  margin-top: 0px;
}

.v-card__actions .v-progress-circular {
  margin-top: 1px !important;
}

.v-card__actions .v-progress-circular circle {
  color: white !important;
}

.storetable .v-input__slot,
.storetable .v-input__control {
  min-height: 40px !important;
  height: 40px;
}

.storetable .v-input--checkbox {
  margin-top: 0px !important;
}

.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: #f3f3f3;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #ddd;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>